import { GetServerSideProps } from 'next';
import { ProductsListWidget } from '@app/products/ProductListWidget';
import { CategoryItem } from '@interfaces';
import {
  getCategoryDetails,
  // getRootCategoriesSlugs,
} from '@services/categories/api';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { useEffect, useState } from 'react';
import { Categories, Page } from '@teddly/teddly-ui-components';
import { useRoutesContext } from '@context/RoutesContext';
import PageNotFound from '@components/Pages/PageNotFound';
import { useChannelContext } from '@context/ChannelContext';
import { CategoryDetailsFragment, useCategoryDetailsQuery } from 'teddly-sdk';
import { getTeddlySdkApi } from '@config/teddly-sdk';
import React from 'react';
import { useHomePageContext } from '@context/HomePageContext';
import { hiddenImageOnError } from '@utils';
import { useRouter } from 'next/router';
import { ProductPageIDs } from '@cypress/components/pages/ProductPage.cy';
// import { CategoryDetails } from 'teddly-sdk/lib/fragments/gqlTypes/CategoryDetails';

type IProps = {
  serverTopCategory: CategoryItem;
  serverError: string;
  categorySlug: string;
};

function TopLevelCategory({
  serverError,
  serverTopCategory,
  categorySlug,
}: IProps): JSX.Element {
  const testIds = new ProductPageIDs();

  const { router, navigateTo, pages } = useRoutesContext();
  const { setLoading, isLoading, addSnackbar, closeSnackbar } =
    usePageLayoutContext();
  const slugs = router.query.categorySlug as string[];
  const currentSlug = slugs?.[slugs?.length - 1];

  const [topCategory, setTopCategory] = useState<CategoryDetailsFragment>(null);
  const [currentCategory, setCurrentCategory] =
    useState<CategoryDetailsFragment>(null);

  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
  const [isCategoryExists, setIsCategoryExists] = useState<boolean | null>(
    null,
  );
  const { isChannelSelected, selectedChannel } = useChannelContext();

  const navigateToHomePage = () => navigateTo({ route: pages.HOME });
  const topCategorySlug = slugs[0];

  const getCurrentCategoryFromResponse = (
    responseCategory: CategoryDetailsFragment,
  ) => {
    if (currentSlug === responseCategory?.slug) {
      return responseCategory;
    }
    const categoryInChildren = responseCategory?.children?.edges?.find(
      (firstChild) => {
        if (firstChild?.node?.slug === currentSlug) {
          return firstChild.node;
        }
        const secondChiled = firstChild?.node?.children?.edges?.find(
          (secondChild) => {
            if (secondChild?.node?.slug === currentSlug) {
              return secondChild.node;
            }
          },
        );
        if (secondChiled) {
          return secondChiled;
        }
      },
    );
    if (categoryInChildren) {
      if (categoryInChildren?.node?.slug === currentSlug) {
        return categoryInChildren?.node;
      }
      return categoryInChildren?.node?.children?.edges?.find(
        (c) => c?.node?.slug === currentSlug,
      )?.node;
    }
    return responseCategory;
  };

  const {
    data: testData,
    loading: testLoading,
    refetch: refetchCategory,
  } = useCategoryDetailsQuery({
    variables: { slug: topCategorySlug },
    fetchPolicy: 'cache-first',
    skip: !isChannelSelected,
  });
  useEffect(() => {
    refetchCategory();
  }, [selectedChannel]);

  const getTopCategory = () => {
    const data = testData.category;
    if (!data) {
      setIsCategoryExists(false);
      if (!isChannelSelected) {
        const snackbarId = 'channelNotSelected';
        addSnackbar({
          id: snackbarId,
          onClose: () => closeSnackbar(snackbarId),
          message: 'Firstly, you must type in your zip code',
          variant: SnackbarVariant.WARNING,
          testId: testIds.snackbarID.warningID,
        });
      }
      if (slugs && !isChannelSelected) {
        navigateTo({
          route: pages.HOME,
          options: {
            queryParams: [`returnUrl=${(slugs as string[])?.join('/')}`],
          },
          isReplace: true,
        });
      }
    } else {
      setIsCategoryExists(true);
      setTopCategory(data);
      const currentCategoryFromResponse = getCurrentCategoryFromResponse(data);
      setCurrentCategory(currentCategoryFromResponse);
    }
    setIsCategoriesLoading(false);
    setLoading(false);
  };

  React.useEffect(() => {
    if (topCategorySlug && !testLoading) {
      getTopCategory();
    }
  }, [topCategorySlug, testLoading]);

  React.useEffect(() => {
    if (!currentSlug) {
      setIsCategoryExists(false);
    }
  }, [currentSlug]);

  // for (let i = 1; i < slugs.length; i++) {
  //   currentCategory = currentCategory?.children?.edges?.find(
  //     (c) => c?.node?.slug === slugs[i]
  //   );
  // }

  // useEffect(() => {
  //   setLoading(!topCategory && !error);
  //   if (!currentCategory && topCategory) {
  //     const snackbarId: string = `${currentSlug}-not-found`;
  //     addSnackbar({
  //       id: snackbarId,
  //       onClose: () => closeSnackbar(snackbarId),
  //       message: `SubCategory ${currentSlug} not found`,
  //       variant: 'error',
  //     });
  //   }
  // }, [topCategory, error]);

  // useEffect(() => {
  //   if (error) {
  //     const snackbarId: string = Date.now().toString();
  //     addSnackbar({
  //       id: snackbarId,
  //       onClose: () => closeSnackbar(snackbarId),
  //       message: error,
  //       variant: 'error',
  //     });
  //   }
  // }, [error]);
  const categories = topCategory?.children?.edges?.map((node, index) => {
    const category = node.node;
    return {
      name: category.name,
      testId: index === 0 ? testIds.categoryBtnID : undefined,
      closeButtonTestId: index === 0 ? testIds.categoryCloseBtnID : undefined,
      icon: category?.backgroundImage?.url ? (
        <img
          alt={category?.backgroundImage?.alt}
          src={category?.backgroundImage?.url}
          onError={hiddenImageOnError}
        />
      ) : null,
      onClick: () => {
        setCurrentCategory(category);
        navigateTo({
          route: [topCategory?.slug, category.slug].join('/'),
          options: { shallow: true },
        });
      },
      onDelete: () => {
        setCurrentCategory(topCategory);
        navigateTo({ route: topCategory?.slug, options: { shallow: true } });
      },
      active:
        currentCategory?.slug == category.slug ||
        category.children?.edges?.findIndex(
          (subCategory) => subCategory.node?.slug === currentCategory?.slug,
        ) >= 0,
      categories: category.children?.edges?.map((subCategory) => {
        return {
          name: subCategory.node?.name,
          icon: subCategory.node.backgroundImage?.url,
          onClick: () => {
            setCurrentCategory(subCategory?.node);
            navigateTo({
              route: [
                topCategory?.slug,
                category.slug,
                subCategory.node?.slug,
              ].join('/'),
              options: { shallow: true },
            });
          },
        };
      }),
    };
  });
  const { bodyFooterInformationProps } = useHomePageContext();

  const clickToLoad = false;
  return categories || isCategoryExists !== false ? (
    <Page.Body
      categories={
        categories || isLoading ? (
          <Categories
            slideTestId={testIds.categorySlidebarID}
            categories={
              categories && currentCategory?.slug == currentSlug
                ? categories
                : []
            }
          />
        ) : (
          []
        )
        // ) : (
        //   <h1 style={{ display: 'flex', justifyContent: 'center' }}>
        //     Sorry no Products Category found...
        //   </h1>
        // )
      }
      isWithNavbar={isChannelSelected}
      {...bodyFooterInformationProps}>
      <>
        <ProductsListWidget
          title={
            currentCategory?.slug === currentSlug ? currentCategory.name : ' '
          }
          fromCategory={currentSlug}
          clickToLoad={clickToLoad}
          showFilters
          waitLoadingForCategories={true}
        />
        {clickToLoad && (
          <ProductsListWidget
            title="Popular items"
            showFilters={false}
            fromCategory={currentSlug}
            clickToLoad={true}
            waitLoadingForCategories={true}
          />
        )}
      </>
    </Page.Body>
  ) : (
    isCategoryExists === false && <PageNotFound />
  );
}

export const getServerSideProps: GetServerSideProps<IProps> = async ({
  params,
}) => {
  const topCategory: CategoryItem | null = null;
  const error: string | null = null;
  const categorySlug = params.categorySlug[0];

  return {
    props: {
      serverError: error,
      serverTopCategory: topCategory,
      categorySlug: categorySlug,
    },
  };
};

export default TopLevelCategory;
